import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import { Flex, Heading, Text } from 'rebass/styled-components'

import Layout from '../layouts'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'
import { PropTypes } from 'prop-types'

const NotFoundPage = (props) => {
  const { data } = props

  return (
    <Layout {...props} locale="en">
      <SEO title="Page not found" />

      <Section>
        <Container maxWidth="400px">
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <Heading as="h1" mb={3} fontSize={7}>
              <FormattedMessage id="page-not-found" defaultMessage="Page not found" />
            </Heading>

            <Img fluid={data.file.childImageSharp.fluid} style={{ maxWidth: '260px', width: '100%', height: '100%', margin: '2rem' }} alt="" />

            <Text as="p" mb={5}>You&rsquo;ve missed the target. Try it from <Link to="/">homepage</Link>.</Text>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPageQuery {
    file(relativePath: { eq: "missed-target-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 746) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
